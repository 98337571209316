import React, { ReactNode, useEffect, useState } from 'react';
import {
  Radio, Form as AntdForm, message, Modal,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { PlusOutlined } from '@ant-design/icons';
import { IUserAuth } from '@bridgelabsdesign/gfox-api-client';
import mixpanel from 'mixpanel-browser';
import Buttons from '../../components/Buttons';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Form from '../../components/Form';
import { stores } from '../../stores/stores';
import { validateMessages } from '../../utils/form';
import { LegalDocuments } from '../../utils/legal';
import { useAuthContext } from '../../context/AuthContext';
import AddressModal from './AddressModal';

interface IRegisterForm {
  name: string;
  label: string;
  whitespace: boolean,
  pattern: RegExp | undefined;
  type: 'string' | 'email' | 'boolean';
  required: boolean;
  formType: 'input' | 'select' | 'radio' | 'checkbox' | 'textarea';
  input: ReactNode;
}

interface IModalContent {
  name: string;
  label: string;
  whitespace: boolean,
  pattern: RegExp | undefined;
  type: 'string' | 'email' | 'boolean';
  required: boolean;
  formType: 'input' | 'select' | 'radio' | 'checkbox' | 'textarea';
  input: ReactNode;

}
interface IBranchForm {
  name: string;
  label1: string;
  label2: string;
  whitespace: boolean,
  required: boolean;
  formType: 'input' | 'select' | 'radio' | 'checkbox' | 'textarea';
  input: ReactNode;

}

interface ILogin {
  name: string;
  label: string;
  whitespace: boolean,
  type: 'string' | 'email' | 'boolean';
  pattern: RegExp | undefined;
  min: number | undefined,
  required: true
  formType: 'input' | 'select' | 'radio' | 'checkbox' | 'textarea';
  input: ReactNode;
}
interface LocationState {
  userAuth?: any;
  client ?: any
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    grecaptcha:any
  }
}

const { Link, Text, Title } = Typography;

const Register = observer(() => {
  const {
    addressStore, branchesStore, emailTokenStore, authStore, userAuthStore,
  } = stores;
  // eslint-disable-next-line prefer-destructuring
  const branchList = branchesStore.branchList;
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [branchId, setBranchID] = useState<string | null>(null);
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [consent, setConsent] = useState<boolean>();
  const [term, setTerm] = useState<boolean>();
  const [form] = AntdForm.useForm();
  const history = useHistory();
  const {
    authenticateUser, currentClient, isLoggedIn, generateAuthKey,
  } = useAuthContext();

  const location = useLocation<LocationState>();
  const { userAuth, client } = location?.state || {};

  const [clientUserAuth, setClientUserAuth] = useState<IUserAuth | null>(null);
  useEffect(() => {
  // Extract the query string part of the URL
    const queryString = window.location.search;

    // Use the URLSearchParams API to parse the query string
    const urlParams = new URLSearchParams(queryString);

    // Get the value of the 'userAuth' parameter, which holds the encoded userAuth
    const userAuthEncoded = urlParams.get('userAuth');

    if (userAuthEncoded) {
      const decodedUserAuthJson = decodeURIComponent(userAuthEncoded);
      const userAuthJson = atob(decodedUserAuthJson);
      const userAuthObj = JSON.parse(userAuthJson);
      setClientUserAuth(userAuthObj);
    } else if (authStore.registeredUserAuth && authStore.registeredClient) {
      const userAuthValues = authStore.registeredUserAuth;
      userAuthValues.clients = [authStore.registeredClient];
      setClientUserAuth(userAuthValues);
    }
  }, []);

  useEffect(() => {
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      // eslint-disable-next-line no-template-curly-in-string
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_REACT_CAPTCHA_CODE}`;
      script.async = true;
      document.body.appendChild(script);
    };

    loadRecaptchaScript();

    return () => {
    // Remove the script element when the component unmounts
      // eslint-disable-next-line max-len
      const script = document.querySelector(`script[src="https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_REACT_CAPTCHA_CODE}"]`);
      if (script) {
        script.remove();
      }
    };
  }, [recaptchaToken]);

  useEffect(() => {
    if (clientUserAuth) {
      const accessClient = clientUserAuth.clients && clientUserAuth.clients.length > 0 ? clientUserAuth.clients[0] : null;
      form.setFieldsValue({
        firstName: clientUserAuth?.firstName || null,
        lastName: clientUserAuth?.lastName || null,
        emailAddress: clientUserAuth?.emailAddress || null,
        billingPhone: accessClient?.billingPhone || null,
        accountNumber: accessClient?.accountNumber || null,
        billingCompany: accessClient?.billingCompany || null,
        claimedAccountNumber: accessClient?.claimedAccountNumber || null,
      });
    }
  }, [clientUserAuth]);

  useEffect(() => {
    if (clientUserAuth?.clients && branchesStore.branchList.length > 0) {
      const clientUserAuthBranchId = clientUserAuth.clients[0]?.branchId;
      const currentBranch = branchesStore.branchList.find((x) => x.id === clientUserAuthBranchId);
      form.setFieldsValue({ branchName: currentBranch?.id });
      setBranchID(currentBranch?.id ?? null);
    }
  }, [branchesStore.branchList]);

  const showModal = () => {
    setIsVerificationModalOpen(true);
  };

  useEffect(() => {
    if (userAuth && client) {
      form.setFieldsValue({
        firstName: userAuth.firstName,
        lastName: userAuth.lastName,
        emailAddress: userAuth.emailAddress,
        billingPhone: client?.billingPhone,
        accountNumber: client?.accountNumber,
        billingCompany: client?.billingCompany,
        claimedAccountNumber: client?.claimedAccountNumber,
        // ...
      });
    }
  }, [userAuth, form]);

  useEffect(() => {
    if (isLoggedIn && currentClient) {
      history.push('/');
    }
  }, [isLoggedIn, currentClient]);

  const handleOk = async () => {
    if (verificationCode == null) {
      message.error('Please enter the verification code');
      return;
    }
    setIsVerificationModalOpen(false);
    try {
      await emailTokenStore.verifyCode(verificationCode ?? '', { emailAddress: authStore.registeredUserAuth?.emailAddress ?? '' }, false);
      const clientObj = await authStore.createProfile(true);
      if (clientObj) {
        if (clientObj?.userAuth.clients) {
          const fallbackAuthkey = generateAuthKey!(clientObj.userAuth.emailAddress, clientObj.userAuth.password);
          await authenticateUser!(clientObj.userAuth.clients[0], fallbackAuthkey);
        }
        history.push('/account/dashboard');
      }
    } catch (error) {
      message.error('Could not verify code');
      console.error(error);
    }
  };

  const handleCancel = () => {
    setIsVerificationModalOpen(false);
  };
  useEffect(() => {
    branchesStore.loadBranchNames();
  }, []);

  const onFinish = async (values: any) => {
    if (addressStore.selectedAddress == null && form.getFieldValue('accountNumber') === undefined) {
      message.error('Add delivery address');
      setIsAddressModalOpen(true);
    } else if (branchId == null) {
      message.error('Branch Name is required');
    } else if (term == null) {
      message.error('Accepting the term and conditions is required');
    } else {
      const hide = message.loading('Sending verification code...', 0);
      // Invoke reCAPTCHA challenge programmatically
      window.grecaptcha.ready(async () => {
        window.grecaptcha.execute(process.env.REACT_APP_REACT_CAPTCHA_CODE, { action: 'submit' })
          .then(async (token: any) => {
            setRecaptchaToken(token);

            // Verify reCAPTCHA token
            const recaptchaVerificationResult = await userAuthStore.handleRecaptchaVerification(token);
            if (!recaptchaVerificationResult) {
              message.error('reCAPTCHA verification failed. Please try again.');
              return;
            }

            try {
              mixpanel.track('Registration Attempt', {
                emailAddress: values.emailAddress,
              });
            } catch (err) {
              console.error(err);
            }

            // Proceed with your backend submission logic here
            authStore.valuesForm(values, branchId as string, consent as boolean);
            try {
              const { emailAddress } = values;
              // eslint-disable-next-line max-len
              const savedUserAuth = await emailTokenStore.sendVerificationCode({ emailAddress, preferredVerificationMethod: 'email', registration: true });
              savedUserAuth.verified = true;
              showModal();
            } catch (error) {
              message.error('Could not send verification code');
            } finally {
              hide();
            }
          });
      });
    }
  };

  const onFinishFailed = () => {
    message.error('Could not login');
  };

  const Details: IRegisterForm[] = [
    {
      name: 'firstName',
      label: 'First Name',
      whitespace: true,
      type: 'string',
      pattern: undefined,
      required: true,
      formType: 'input',
      input: <Form.Input className={styles.inputBackground} placeholder="Enter your name" />,
    },

    {
      name: 'lastName',
      label: 'Last Name',
      whitespace: true,
      type: 'string',
      pattern: undefined,
      required: true,
      formType: 'input',
      input: <Form.Input className={styles.inputBackground} placeholder="Enter your surname" />,
    },

    {
      name: 'billingCompany',
      label: 'Company Name',
      whitespace: false,
      type: 'string',
      pattern: undefined,
      required: false,
      formType: 'input',
      input: <Form.Input className={styles.inputBackground} placeholder="Enter your company name" />,
    },
    {
      name: 'billingPhone',
      label: 'Phone number',
      whitespace: true,
      pattern: new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'),
      type: 'string',
      required: true,
      formType: 'input',
      input: <Form.Input className={styles.inputBackground} placeholder="Enter your phone number" />,
    },
  ];
  const Branch: IBranchForm[] = [
    {
      name: 'branchName',
      label1: 'This the branch through which your orders will be processed',
      required: false,
      label2: '',
      whitespace: true,
      formType: 'select',
      input: (
        <Form.Select
          onChange={(value) => setBranchID(value)}
          options={branchList.map((x) => ({ value: x.id, label: x.branchName }))}
          placeholder="Select your nearest branch"
        />
      ),
    },

    {
      name: 'accountNumber',
      label1: 'If you have an account with G.FOX enter your account number below',
      label2: 'Note: your account will not be active until your account number has been verified',
      whitespace: true,
      required: false,
      formType: 'input',
      input: <Form.Input className={styles.inputBackground} placeholder="Enter your account number" />,
    },

  ];

  const Login: ILogin[] = [
    {
      name: 'emailAddress',
      label: 'Email address',
      whitespace: true,
      type: 'email',
      pattern: undefined,
      min: undefined,
      required: true,
      formType: 'input',
      input: <Form.Input className={styles.inputBackground} placeholder="Enter your email" />,
    },
    {
      name: 'password',
      label: 'Enter your password',
      whitespace: true,
      required: true,
      type: 'string',
      min: 8,
      pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/),
      formType: 'input',
      input: <Form.InputPassword className={styles.inputBackground} placeholder="Enter your password" />,

    },
    {
      name: 'confirmPassword',
      type: 'string',
      pattern: undefined,
      label: 'Confirm password',
      whitespace: true,
      min: undefined,
      required: true,
      formType: 'input',
      input: <Form.InputPassword className={styles.inputBackground} placeholder="Confirm your password" />,
    },

  ];
  const ModalContent: IModalContent[] = [
    {
      name: 'verificationEmail',
      whitespace: true,
      type: 'string',
      pattern: undefined,
      required: false,
      formType: 'input',
      label: 'Please enter the verification code sent to your email',
      input: <Form.Input
        className={styles.inputBackground}
        placeholder="Enter Verification Code "
        onChange={(value) => setVerificationCode(value.target.value)}
      />,
    },
  ];
  return (

    <main id="register">
      <Helmet>
        <title>Register - G. Fox</title>
      </Helmet>

      <AntdForm
        name="register"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
        validateMessages={validateMessages}
        form={form}
      >

        <div className={styles.tabCon}>
          <Title
            className={styles.registerHeader}
            level={2}
          >
            Register
          </Title>
          <div className={styles.detailsCon}>
            <Text className={styles.detailsText}>
              Your Details:
            </Text>

            <div className={styles.inputcon}>
              {React.Children.toArray(
                Details.map((item) => (
                  <div
                    className={[
                      styles.registerFormCon,
                      item.formType === 'input' && styles.registerFormConInput,
                      item.formType === 'textarea' && styles.registerFormConTextArea,
                    ].join(' ')}
                  >
                    <Text className={styles.registerLabel}>{item.label}</Text>
                    <AntdForm.Item
                      name={item.name}
                      required={item.required}
                      className={styles.regsiterFormItem}
                      rules={[
                        { required: item.required },
                        { type: item.type, message: 'Please enter a valid email' },
                        { pattern: item.pattern, message: 'invalid format' }, { whitespace: item.whitespace },
                      ]}
                      help={item.name === 'billingPhone' ? 'Please enter your number in the format +2712345678 or 0712345678'
                        : undefined}
                    >

                      {item.input}

                    </AntdForm.Item>

                  </div>
                )),
              )}
            </div>
          </div>
          <div className={styles.detailsCon}>
            <Text className={styles.detailsText}>
              Select your nearest branch:
            </Text>
            <div className={styles.inputcon}>
              {React.Children.toArray(
                Branch.map((item) => (
                  <div
                    className={[
                      styles.registerFormCon,
                      item.formType === 'input' && styles.registerFormConInput,
                      item.formType === 'textarea' && styles.registerFormConTextArea,
                    ].join(' ')}
                  >
                    <Text className={styles.registerLabel}>{item.label1}</Text>
                    <br />
                    <Text className={styles.registerredSmall}>{item.label2}</Text>
                    <AntdForm.Item
                      name={item.name}
                      className={styles.regsiterFormItem}
                      required={item.required}
                      rules={[{ required: item.required }, { whitespace: false }]}
                    >
                      {item.input}
                    </AntdForm.Item>
                  </div>
                )),
              )}
            </div>
          </div>

          <div className={styles.detailsCon}>
            <div>
              <Text className={styles.detailsText}>
                Delivery Address:
              </Text>
            </div>

            <div>
              <Buttons.PrimaryBtn
                type="primary"
                htmlType="button"
                style={{ marginTop: '1em' }}
                onClick={() => setIsAddressModalOpen(true)}
              >
                Add Delivery Address
              </Buttons.PrimaryBtn>
            </div>

            <AddressModal
              isOpen={isAddressModalOpen}
              handleCancel={() => setIsAddressModalOpen(false)}
              handleOk={() => setIsAddressModalOpen(false)}
              form={form}
            />
          </div>

          <div id="login" className={styles.detailsCon}>
            <Text className={styles.detailsText}>
              Login details:
            </Text>
            <div className={styles.inputcon}>
              {React.Children.toArray(
                Login.map((item) => (
                  <div
                    className={[
                      styles.registerFormCon,
                      item.formType === 'input' && styles.registerFormConInput,
                      item.formType === 'textarea' && styles.registerFormConTextArea,
                    ].join(' ')}
                  >
                    <Text className={styles.registerLabel}>{item.label}</Text>
                    <AntdForm.Item
                      name={item.name}
                      required={item.required}
                      className={styles.regsiterFormItem}
                      rules={[{ required: item.required }, { min: item.min },
                        { whitespace: item.whitespace },
                        { type: item.type, message: 'Please enter a valid email' },
                        { pattern: item.pattern, message: "'Minimum 8 characters, 1 upper case, number, 1 special character'" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value || item.name !== 'confirmPassword') {
                              return Promise.resolve();
                            }
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject(
                              'The two passwords that you entered does not match.',
                            );
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      {item.input}
                    </AntdForm.Item>
                  </div>
                )),
              )}
              <div className={styles.registerRadioGrp}>

                {/* TODO: should sub or unsub to mailing list */}
                <Radio checked={consent} onClick={() => setConsent(!consent)}>
                  Receive future communications and promotional special from G.Fox

                </Radio>
                <Radio checked={term} onClick={() => setTerm(!term)}>
                  I have and agree to the website
                  {' '}
                  <Link style={{ display: 'inline-block' }} href={LegalDocuments.TRADING_TERMS} external>
                    {' '}
                    <Text className={styles.registerLabelredBold}>terms and conditions.</Text>
                  </Link>

                  {' '}
                </Radio>

              </div>
              <div className={styles.detailsCon}>
                <Text className={styles.registerLabelredBold}>
                  Please note:
                </Text>
                <br />
                <Text className={styles.registerLabelblack}>
                  {/* eslint-disable-next-line max-len */}
                  Your personal data will be used to support your experience throughout this website,
                  to manage access to your account, and for other purposes described in
                  {' '}
                </Text>
                <Link style={{ display: 'inline-block' }} href={LegalDocuments.PRIVACY_POLICY} external>
                  <Text className={styles.registerLabelredBold}> privacy policy.</Text>
                </Link>
              </div>
              <div className={styles.detailsCon}>
                <Buttons.PrimaryBtn
                  htmlType="submit"
                >
                  Save
                </Buttons.PrimaryBtn>
              </div>
              <Modal
                title="Verification"
                visible={isVerificationModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <div className={styles.inputcon}>
                  {React.Children.toArray(
                    ModalContent.map((item) => (
                      <div
                        className={[
                          styles.registerFormCon,
                          item.formType === 'input' && styles.registerFormConInput,
                          item.formType === 'textarea' && styles.registerFormConTextArea,
                        ].join(' ')}
                      >
                        <Text className={styles.registerLabel}>{item.label}</Text>
                        <AntdForm.Item
                          name={item.name}
                          required={item.required}
                          rules={[{ required: item.required }, { whitespace: item.whitespace }]}
                        >
                          <div className={styles.regsiterFormItem}>{item.input}</div>
                        </AntdForm.Item>
                        <Text style={{ color: 'red', fontSize: '1.2rem' }}>
                          If you have an issue getting an OTP, please contact 067 238 4768.
                        </Text>
                      </div>
                    )),
                  )}
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </AntdForm>

    </main>

  );
});
export default Register;
