import APIClient, {
  IClient, IUserAuth, AccountPermission, ILinkedAccount, interceptor,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import notifications from '../components/Notifications/notifications';
import { isEmptyString } from '../utils/strings';

export interface IAuthContext {
    authKey?: any,
    setAuthKey?: any,
    isLoggedIn?: boolean,
    setIsLoggedIn?: any,
    // eslint-disable-next-line no-unused-vars
    authenticateUser?: (client?: IClient, fallbackAuthkey?: string) => Promise<void>,
    logoutUser?:() => Promise<void>,
    currentClient?: IClient | null,
    setCurrentClient?: any,
    currentUser?: IUserAuth | null,
    setCurrentUser?: any,
    hasRoutePermission?: any
    linkedAccount?: ILinkedAccount[];
    // eslint-disable-next-line no-unused-vars
    registerUserAuth?(userAuth: IUserAuth): void;
    // eslint-disable-next-line no-unused-vars
    generateAuthKey?: (email: string, password: string) => string
    // eslint-disable-next-line no-unused-vars
    getCurrentClient?: (showMessageError?: boolean) => Promise<IClient|undefined>;

    // eslint-disable-next-line no-unused-vars
    checkUserExists?(userAuth: IUserAuth): Promise<'success' | 'not-found' | 'error'>;

}

const AuthContext = React.createContext<IAuthContext>({});

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
  const history = useHistory();
  const [authKey, setAuthKey] = React.useState<any>();
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [currentClient, setCurrentClient] = React.useState<IClient | null>(null);
  const [currentUser, setCurrentUser] = React.useState<IUserAuth|null>(null);
  const [linkedAccount, setLinkedAccount] = React.useState<ILinkedAccount[]>([]);

  if (!currentClient) {
    mixpanel.identify('client:id:anonymous');
  } else {
    mixpanel.identify(`client:id:${currentClient?.id ?? '_failed'}`);
    mixpanel.people.set({
      emailAddress: currentClient?.userAuth?.emailAddress ?? '_failed',
    });
  }

  const getCurrentClient = async (showMessageError = true): Promise<IClient|undefined> => {
    try {
      const res = await APIClient.Client.authorizeClient();
      setCurrentClient(res);
      return res;
    } catch (error) {
      if (showMessageError) {
        message.error('Could not load account details.');
      }
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        if (e?.response?.status === 401 || e?.response?.status === 404) {
          return undefined;
        }
      }

      console.error(error);
      return undefined;
    }
  };

  const fetchLinkedAccount = async (clientId: string, userAuthId: string, accountNumber: string) => {
    // get linked accounts
    const queryParameters = `userAuthId=${userAuthId}&`;
    const linkedAccountsResponse = await APIClient.LinkedAccount.getLinkedAccounts(queryParameters);
    const linkedAcc = linkedAccountsResponse.data;

    // check for other, is main account, and has unverified accounts
    if (linkedAcc.length > 1) {
      const account = linkedAcc.find((x) => x.userAuthId === userAuthId);
      const isAdmin = account?.userPermissions.includes('Admin'); // TODO: provide a type for this
      if (account?.verified && isAdmin) {
        const unverifiedAccounts = linkedAcc.some((x) => !x.verified && x.userAuthId !== userAuthId);
        if (unverifiedAccounts) {
          notifications.unverifiedAccountsAdmin(accountNumber);
        }
      }
    }

    setLinkedAccount(linkedAcc);
  };

  const authenticateUser = async (client?: IClient, fallbackAuthkey?: string) => {
    setIsLoggedIn(true);

    let userAuthId = client?.userAuthId;
    if (!client) {
      let result = await getCurrentClient(false);
      if (!result && fallbackAuthkey) {
        await interceptor({ authKey: fallbackAuthkey });
        result = await getCurrentClient(true);
        localStorage.setItem('authKey', fallbackAuthkey);
        if (!result) {
          await interceptor({ authKey: undefined });
          localStorage.removeItem('authKey');
        }
      }

      if (!result) {
        throw new Error('getCurrentClient() error');
      } else {
        userAuthId = result.userAuthId;
      }
    } else {
      setCurrentClient(client);
    }

    if (client?.id && userAuthId) {
      if (!isEmptyString(client?.accountNumber)) {
        await fetchLinkedAccount(client.id!, userAuthId, client.accountNumber);
      }
    }
  };

  const generateAuthKey = (email: string, password: string): string => Buffer.from(`${email}:${password}`).toString('base64');

  const logoutUser = async () => {
    const hide = message.loading('Logging out...');
    setIsLoggedIn(false);
    try {
      await APIClient.Client.logout();
    } catch (err) {
      console.error(err);
    }
    setCurrentClient(null);
    localStorage.removeItem('authKey');
    await new Promise((resolve) => {
      setTimeout(() => resolve(undefined), 1000);
    });

    mixpanel.identify('client:id:anonymous');
    window.location.assign('/account/login'); // force reset all state
    hide();
  };

  const checkUserExists = async (userAuth: IUserAuth): Promise<'success' | 'not-found' | 'error'> => {
    try {
      await APIClient.UserAuth.googleAuth(userAuth);
      authenticateUser();
      return 'success';
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<IUserAuth>;
        if (e?.response?.status === 401) {
          return 'not-found';
        }
      }
      console.warn(error);
      return 'error';
    }
  };

  const registerUserAuth = async (userAuth: IUserAuth) => {
    try {
      await APIClient.UserAuth.googleAuth(userAuth);
      authenticateUser();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<IUserAuth>;
        if (e?.response?.status === 401) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const userAuth = e?.response?.data;
          const client = userAuth.clients![0];

          history.push({
            pathname: '/account/register',
            state: {
              client,
              userAuth,
            },
          });

          return;
        }
      }
      console.warn(error);
    }
  };
  const isAuthorizingClient = useRef(false);

  useEffect(() => {
    (async () => {
      if (isAuthorizingClient.current) {
        return;
      }

      isAuthorizingClient.current = true;
      let loggedIn = false;

      const userAuthKey = localStorage.getItem('authKey');
      if (userAuthKey != null) {
        await interceptor({ authKey: userAuthKey });
      }
      const client = await getCurrentClient(false);

      if (client) {
        loggedIn = true;
        setIsLoggedIn(loggedIn);
        setCurrentClient(client);
      } else {
        setIsLoggedIn(loggedIn);
      }

      isAuthorizingClient.current = false;
    })();
  }, [getCurrentClient, setIsLoggedIn]);

  const accountPermissionToString = (permission: AccountPermission): string => {
    switch (permission) {
      case AccountPermission.View:
        return 'View';
      case AccountPermission.Admin:
        return 'Admin';
      case AccountPermission.AccountAndPayments:
        return 'AccountAndPayments';
      case AccountPermission.Orders:
        return 'Orders';
      default:
        return '';
    }
  };

  const hasRoutePermission = (routePermissions: AccountPermission[]): boolean =>
    // eslint-disable-next-line implicit-arrow-linebreak
    linkedAccount.some((x) => {
      const userPermissions = x.userPermissions ?? [];
      return routePermissions.some((permission) => {
        const result = userPermissions.includes(accountPermissionToString(permission));
        return result;
      });
    });
  return (

    <AuthContext.Provider
      value={{
        authKey,
        setAuthKey,
        isLoggedIn,
        setIsLoggedIn,
        currentClient,
        setCurrentClient,
        authenticateUser,
        currentUser,
        setCurrentUser,
        logoutUser,
        generateAuthKey,
        getCurrentClient,
        registerUserAuth,
        hasRoutePermission,
        checkUserExists,
        linkedAccount,
      }}
    >
      {children}
    </AuthContext.Provider>

  );
};
