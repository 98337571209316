/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { message, Steps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AccountPermission, IOrder, OrderPlatform,
} from '@bridgelabsdesign/gfox-api-client';
import mixpanel from 'mixpanel-browser';
import Buttons from '../components/Buttons';
import Menu from '../components/Menu/Menu';
import Typography from '../components/Typography';
import useWindowSize from '../utils/hooks/useWindowSize';
import Header from './Header';
import styles from './checkout.module.css';
import { CheckoutPath, checkoutSteps } from '../utils/checkout';
import stores from '../stores/stores';
import Cards from '../components/Cards';
import { useAuthContext } from '../context/AuthContext';
import { OrderStatus } from '../utils/order/status';
import { isGFoxAccount } from '../utils/account/account-holder';
import QuoteReorder from '../utils/quotes/quote-reorder';
import { isEmptyString } from '../utils/strings';
import { getStockMessage } from '../utils/order/stockMessage';

interface ICheckoutLayout {
  component: JSX.Element,
}

const { Text, Title } = Typography;

const CheckoutLayout = observer(({
  component: Component,
}: ICheckoutLayout) => {
  const { currentClient, isLoggedIn } = useAuthContext();
  const { isMobileWindow, isTabletWindow } = useWindowSize();
  const history = useHistory();
  const location = useLocation();

  const {
    addressStore, clientStore, cartStore, checkoutStore, deliveryStore, linkedAccountsStore,
    quotesStore, orderStore, wishStore,
  } = stores;

  const isCheckingOut = useRef(false);
  const currentStep = checkoutStore.checkoutStep;
  const cartItems = cartStore.cartItems.value.data;
  const wishItems = wishStore.wishItems.value.data;

  if (checkoutStore.isPayStackProcessing) {
    history.push('/paystack/callback/?reference=redirect');
    checkoutStore.isPayStackProcessing = false;
  }

  const handleReLinkAccount = async () => {
    const emailAddress = currentClient?.userAuth?.emailAddress;
    if (!currentClient || !emailAddress) {
      message.error('Could not link account');
      return;
    }

    // TODO: relink account to not set permission to admin
    await linkedAccountsStore.requestAccountLink(currentClient?.accountNumber, emailAddress, [AccountPermission.Admin]);
  };

  const handleCheckout = async (isQuote: boolean) => {
    // checkout that voucher was only applied to an order
    if (isQuote && cartStore.voucherClaim?.claimId) {
      message.error('Voucher cannot be applied to quote. Please remove voucher to continue', 6);
      return;
    }

    // Check Ts & Cs
    if (!isQuote && !checkoutStore.acceptTsAndCs) {
      message.error('Please accept the T&C’s before continuing.');
      return;
    }

    // account valid status
    if (!isQuote) {
      const accountValid = await clientStore.accountValid(currentClient?.id!, {
        accountNumber: currentClient?.accountNumber,
        onReRequestValidation: () => handleReLinkAccount(),
        sendOnEmail: true,
      });
      if (!accountValid) {
        return;
      }
    }

    const clientId = currentClient?.id!;
    const addressId = addressStore.selectedAddress?.id!;
    const { selectedDelivery } = deliveryStore;
    const cartQty: { [sku: string]: number } = cartItems.reduce((p, c) => ({ ...p, [c.inventoryMaster?.sku]: c.quantity }), {});
    const invList = cartStore.invList.value.data.map((x) => ({ ...x, orderQty: cartQty[x.sku] }));

    if (isQuote) {
      const generated = await quotesStore.generateQuote(
        clientId,
        addressId,
        invList,
        selectedDelivery,
        checkoutStore.purchaseQuoteNo,
        cartStore.voucherClaim?.claimId,
      );
      if (generated) {
        await quotesStore.loadQuotes(clientId);
        await cartStore.clearCart(clientId);
        history.push('/account/quotes');
      }

      try {
        mixpanel.track('Quote Generated', {
        });
      } catch (err) {
        console.error(err);
      }

      return;
    }

    // no qoutes to be linked when voucher applied
    let quoteId: string | null = null;
    if (!cartStore.voucherClaim?.claimId) {
      quoteId = await quotesStore.linkOrder(clientId, invList.slice());
    }

    const order: Partial<IOrder> = {
      addressId,
      comments: checkoutStore.orderComments,
      orderInfo: orderStore.getOrderInfo(invList),
      clientId,
      totalPrice: cartStore.cartItemsInvPricing.totalWithVat,
      customerOrderNo: checkoutStore?.customerOrderNo,
      requestedShipDate: checkoutStore?.requestedShipDate,
      vat: 0,
      grandTotal: checkoutStore.totalPrice,
      statusCode: OrderStatus.PROCESSING_PAYMENT,
      deliveryOrders: [selectedDelivery?.deliveryOrders[0]!],
      quoteId,
      platform: OrderPlatform.WEB,
      voucherClaimId: cartStore.voucherClaim?.claimId,
    };

    if (isGFoxAccount(currentClient!) && checkoutStore.selectedAccountPaymentMethod) {
      const orderCreated = await orderStore.accountHolderCheckout(order as IOrder);
      if (orderCreated) {
        await orderStore.loadPreviousOrders(clientId, true);
        await cartStore.clearCart(clientId);
        await QuoteReorder.removeRefNo();
        history.push('/account/orders');
      }

      try {
        mixpanel.track('Order Generated', {
        });
      } catch (err) {
        console.error(err);
      }
      return;
    }

    const authorization = checkoutStore.selectedPaystackMethod?.authorization;
    const result = await orderStore.checkout(order as IOrder, authorization?.authorization_code);
    if (!isEmptyString(result?.data?.authorization_url)) {
      if (authorization) {
        window.open(result?.data?.authorization_url, '_blank');
        checkoutStore.setPayStackProcess(true);
      } else {
        window.open(result?.data?.authorization_url!);
      }
    } else {
      // saved charge authorization was used
      await orderStore.loadPreviousOrders(clientId, true);
      await cartStore.clearCart(clientId);
      await QuoteReorder.removeRefNo();
      history.push('/account/orders');
    }

    try {
      mixpanel.track('Order Generated', {
      });
    } catch (err) {
      console.error(err);
    }
  };

  const transferWishToCart = async () => {
    let itemsToTransfer = wishItems;
    if (wishStore.selectedItems.length > 0) {
      itemsToTransfer = wishStore.selectedItems;
    }
    await wishStore.transferWishToCart(currentClient!, cartStore.cartItems.value.data, itemsToTransfer);
    await cartStore.loadCartItems(currentClient!.id);
    history.push(CheckoutPath.CART);
  };

  const handleContinueOrder = async () => {
    if (cartItems.length === 0 && !(checkoutStore.checkoutStep?.path === CheckoutPath.WISHLIST)) {
      message.error('Please add items in cart to continue.');
      return;
    }

    // New check for Purchase Order Number
    // eslint-disable-next-line max-len
    if ((checkoutStore.checkoutStep?.path === CheckoutPath.CUSTOMER || checkoutStore.checkoutStep?.path === CheckoutPath.PAYMENT) && (!checkoutStore.customerOrderNo || isEmptyString(checkoutStore.customerOrderNo))) {
      message.error('Please fill in the Purchase Order Number.');
      return;
    }
    // eslint-disable-next-line
    const stockMessage = cartItems?.map((item) => {
      const masterStock = cartStore.invList.value.data.find((x) => x.id === item.inventoryMasterId)?.masterStock;
      return getStockMessage(
        { lineItemCount: item.quantity },
        { isAppPrice: masterStock?.isAppPrice ?? false, qtyAvailable: masterStock?.qtyAvailable ?? 0 },
        currentClient,
      );
    }).find((foundMessage) => (foundMessage.isOutOfStock) && (foundMessage.isCOD || foundMessage.isAppPrice));

    if (stockMessage) {
      message.error(stockMessage.message);
      return;
    }

    if (!checkoutStore.nextCheckoutStep?.path) {
      if (!isCheckingOut.current) {
        isCheckingOut.current = true;
        await handleCheckout(false)
          .finally(() => { isCheckingOut.current = false; });
      }
      return;
    }
    if (checkoutStore.checkoutStep?.path === CheckoutPath.WISHLIST) {
      if (isLoggedIn) {
        await transferWishToCart();
        return;
      }
      history.push(`/account/login?returnUrl=${location.pathname}`);
      message.error('Please login/register to continue');
      return;
    }

    if (checkoutStore.nextCheckoutStep?.path === CheckoutPath.LOGIN) {
      if (!isLoggedIn) {
        history.push(`/account/login?returnUrl=${location.pathname}`);
      } else {
        history.push(CheckoutPath.CUSTOMER);
      }
      return;
    }
    history.push(checkoutStore.nextCheckoutStep.path);
  };

  const handleBackNavigation = () => {
    history.goBack();
  };

  useEffect(() => {
    checkoutStore.setStepIndexFromPath(location.pathname);
  }, [location]);

  const loadInventoryAndStock = async () => {
    if (cartItems.length > 0) {
      const skuList = cartItems.reduce((p, c, idx) => (idx === 0 ? c.inventoryMaster?.sku : `${p},${c.inventoryMaster?.sku}`), '');
      await cartStore.loadInventoryList({ skuList });
    }
  };
  useEffect(() => {
    loadInventoryAndStock();
  }, [cartItems, currentClient?.id]);

  useEffect(() => {
    const skuList = wishItems.reduce((p, c, idx) => (idx === 0 ? c.inventoryMaster?.sku : `${p},${c.inventoryMaster?.sku}`), '');
    wishStore.loadInventoryList({ skuList });
  }, []);

  useEffect(() => {
    isCheckingOut.current = false;
    cartStore.setVoucherClaim(undefined);
    return () => {
      isCheckingOut.current = false;
      cartStore.setVoucherClaim(undefined);
    };
  }, []);

  return (
    <Header
      headerContent={<Menu direction="row" />}
      component={() => (
        <div className={styles.checkoutConBg}>
          <div className={styles.checkoutCon}>
            <Title
              className={styles.checkoutPageTitle}
              level={2}
            >
              {(isMobileWindow || isTabletWindow) ? 'Shopping Cart' : currentStep?.title}
            </Title>

            {currentStep?.path === CheckoutPath.WISHLIST ? (
              <Buttons.PrimaryBtn
                disabled={wishItems.length === 0}
                onClick={() => handleContinueOrder()}
              >
                Add to Cart
                {!(isMobileWindow || isTabletWindow) && <FiChevronRight className={styles.checkoutOptsBackArrow} />}
              </Buttons.PrimaryBtn>
            ) : (
              <div className={styles.checkoutStepsCon}>
                {/* TODO: extract into seperate component */}
                <Steps
                  size={(isMobileWindow || isTabletWindow) ? 'small' : 'default'}
                  direction="horizontal"
                  current={currentStep?.step && currentStep.step - 1}
                  responsive={false}
                  labelPlacement="vertical"
                >
                  {checkoutSteps.map((item) => (
                    <Steps.Step
                      key={item.title}
                      title={!(isMobileWindow || isTabletWindow) && (
                        <Text className={styles.checkoutStepsTitle}>
                          {item.title}
                        </Text>
                      )}
                    />
                  ))}
                </Steps>
                <div>
                  {(isMobileWindow || isTabletWindow) && (
                  <Text className={styles.checkoutStepsMobTitle}>
                    {currentStep?.title}
                  </Text>
                  )}
                </div>
              </div>
            )}
            {currentStep?.path === CheckoutPath.SUMMARY && (
              <div className={styles.checkoutHeaderCompCon}>
                <Cards.OrderSummaryCard />
              </div>
            )}

            <div
              className={[
                styles.checkoutMainCon,
                currentStep?.path === CheckoutPath.SUMMARY && styles.checkoutMainConFlexReverse,
              ].join(' ')}
            >
              <div className={styles.checkoutContent}>
                <Text className={styles.checkoutContentHeader}>
                  {currentStep?.header}
                </Text>
                {Component}
              </div>

              {currentStep?.path !== CheckoutPath.WISHLIST && (
                <div className={styles.checkoutSidePanel}>
                  <Text className={styles.checkoutContentHeader}>
                    Your Order
                  </Text>
                  <div className={styles.checkoutPayCon}>
                    <Buttons.PrimaryBtn
                      className={styles.checkoutOptsTopBtn}
                      disabled={cartItems.length === 0}
                      onClick={() => handleContinueOrder()}
                    >
                      {
                      currentStep?.path === CheckoutPath.SUMMARY && currentClient?.accountNumber
                        ? 'Place Order'
                        : currentStep?.path === CheckoutPath.SUMMARY
                          ? 'Pay Now'
                          : 'Continue Order'
                          }
                      {!(isMobileWindow || isTabletWindow) && <FiChevronRight className={styles.checkoutOptsBackArrow} />}
                    </Buttons.PrimaryBtn>
                    <Buttons.PrimaryBtn
                      className={styles.saveAsStandingOrder}
                      type="ghost"
                      disabled={cartItems.length === 0}
                      onClick={async () => {
                        if (!isCheckingOut.current) {
                          isCheckingOut.current = true;
                          await handleCheckout(true)
                            .finally(() => { isCheckingOut.current = false; });
                        }
                      }}
                    >
                      Generate Quote
                    </Buttons.PrimaryBtn>
                  </div>
                  <Cards.CheckoutSummaryCard />
                </div>
              )}
            </div>
            <div className={styles.checkoutOptsCon}>
              <Buttons.PrimaryBtn
                onClick={() => handleBackNavigation()}
                type={(isMobileWindow || isTabletWindow) ? 'text' : 'ghost'}
              >
                <FiChevronLeft className={styles.checkoutOptsBackArrow} />
                Back
              </Buttons.PrimaryBtn>
              <Buttons.PrimaryBtn
                className={styles.saveAsStandingOrder}
                type="ghost"
                disabled={cartItems.length === 0}
                onClick={async () => {
                  if (!isCheckingOut.current) {
                    isCheckingOut.current = true;
                    await handleCheckout(true)
                      .finally(() => { isCheckingOut.current = false; });
                  }
                }}
              >
                Generate Quote
              </Buttons.PrimaryBtn>
              <Buttons.PrimaryBtn
                className={styles.checkoutOptsBtn}
                disabled={currentStep?.path === CheckoutPath.WISHLIST
                  ? wishItems.length === 0
                  : cartItems.length === 0}
                onClick={() => handleContinueOrder()}
              >
                {
                    currentStep?.path === CheckoutPath.SUMMARY && currentClient?.accountNumber
                      ? 'Place Order'
                      : currentStep?.path === CheckoutPath.SUMMARY
                        ? 'Pay Now'
                        : currentStep?.path === CheckoutPath.WISHLIST
                          ? 'Add to Cart'
                          : 'Continue Order'
                        }
                {!(isMobileWindow || isTabletWindow) && <FiChevronRight className={styles.checkoutOptsBackArrow} />}
              </Buttons.PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    />
  );
});

export default CheckoutLayout;
