/* eslint-disable camelcase */
import React, {
  ReactNode, useEffect, useState,
} from 'react';
import {
  Button,
  Form as AntdForm, message, Modal, Checkbox,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { IClient, ILoginRequest, IUserAuth } from '@bridgelabsdesign/gfox-api-client';
import { useForm } from 'antd/es/form/Form';
import { IoClose } from 'react-icons/io5';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import mixpanel from 'mixpanel-browser';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import Form from '../../components/Form';
import Buttons from '../../components/Buttons';
import Images from '../../assets';
import useQuery from '../../utils/hooks/useQuery';
import { validateMessages } from '../../utils/form';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
// import AppleButton from '../../components/Buttons/AppleBtn';

declare let google:any;

const { Text, Title } = Typography;

const Login = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [loginUserAuth, setUserAuth] = useState<IUserAuth | undefined>(undefined);
  const [isSMS, setIsSMS] = useState(false);
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState('');
  const [staySignedIn, setStaySignedIn] = useState(false);
  // const [verificationMethod, setVerificationMethod] = useState('email');

  const {
    authenticateUser, isLoggedIn, checkUserExists, generateAuthKey,
  } = useAuthContext();
  const { authStore, userAuthStore, emailTokenStore } = stores;
  const history = useHistory();

  const handleCredentialResponse = async (responseFromGoogle: any) => {
    throw new Error('TODO: retest on renabling feature (especially on safari)');
    // eslint-disable-next-line
    const { credential } = responseFromGoogle;
    // Decode the ID token to get user information
    const tokenPayload = JSON.parse(atob(credential.split('.')[1]));
    const {
      email, given_name, family_name,
    } = tokenPayload;

    const { accessToken } = credential;
    const { refreshToken } = credential;
    const expiryDate = new Date(Date.now() + credential.expiresIn * 1000);

    // Create a new IUserAuth object with the required properties
    const userAuth = {
      firstName: given_name,
      lastName: family_name,
      emailAddress: email,
      verified: false,
      registeredAt: new Date(),
      authType: 'google',
      authToken: accessToken,
      authTokenExpiry: expiryDate,
      refreshToken,
      refreshTokenExpiry: null,
      clients: [
            {
              billingPhone: null,
              accountNumber: null,
              billingCompany: null,
              claimedAccountNumber: null,
            } as unknown as IClient,
      ],
    } as IUserAuth;

    // Check if user is in the database
    const status = await checkUserExists!(userAuth);
    if (status === 'success') {
      history.push('/account/dashboard');
    } else if (status === 'not-found') {
      const client = userAuth.clients![0];
      history.push({
        pathname: '/account/register',
        state: {
          userAuth,
          client,
        },
      });
    }
    // do nothing for other error cases
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => {
      google.accounts.id.initialize({
        client_id: '785469471536-kdvi0qgukgfab4ajv422fn38ogsjcl3j.apps.googleusercontent.com',
        context: 'use',
        callback: handleCredentialResponse,
      });
      // Render the One Tap button
      google.accounts.id.renderButton(
        document.getElementById('google-one-tap-button'),
        { theme: 'outline', size: 'large', text: 'Sign in with Google' },
      );
    };
  }, []);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const [clientUserAuth, setClientUserAuth] = useState<IUserAuth | null>(null);

  // First useEffect: decode and set clientUserAuth
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userAuthEncoded = urlParams.get('userAuth');

    if (userAuthEncoded) {
      const decodedUserAuthJson = decodeURIComponent(userAuthEncoded);
      const userAuthJson = atob(decodedUserAuthJson);
      const userAuthObj = JSON.parse(userAuthJson);
      setClientUserAuth(userAuthObj);
    }
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (clientUserAuth?.clients && clientUserAuth.clients.length > 0) {
      const authenticateAndNavigate = async () => {
        try {
          if (clientUserAuth?.clients) {
            const fallbackAuthkey = generateAuthKey!(clientUserAuth.emailAddress, clientUserAuth.password);
            await authenticateUser!(clientUserAuth.clients[0], fallbackAuthkey);
            history.push('/account/dashboard');
          }
        } catch (error) {
          message.error('User could not be authenticated');
        }
      };

      authenticateAndNavigate();
    }
  }, [clientUserAuth]);

  const [resetLoginForm] = useForm();
  const navigate = useHistory();
  const query = useQuery();

  const handleEnter = () => {
    navigate.push('/account/register');
  };

  const onChange = (e:CheckboxChangeEvent) => {
    setStaySignedIn(e.target.checked);
  };

  const handleReturnUrl = () => {
    const returnUrl = query.get('returnUrl');
    if (!isLoggedIn) {
      return;
    }
    if (returnUrl !== null) {
      navigate.replace(returnUrl);
      return;
    }
    navigate.replace('/account/dashboard');
  };

  const handleOk = async () => {
    if (verificationCode == null) {
      message.error('Please enter the verification code');
      return;
    }
    setIsModalOpen(false);
    if (loginUserAuth) {
      try {
        await emailTokenStore.verifyCode(verificationCode ?? '', { emailAddress: loginUserAuth.emailAddress ?? '' }, true);
        const fallbackAuthkey = generateAuthKey!(loginUserAuth.emailAddress, loginUserAuth.password);
        await authenticateUser!(undefined, fallbackAuthkey);
        history.push({
          pathname: '/account/dashboard',
        });
      } catch (error) {
        message.error('Could not verify code');
      }
    } else {
      message.error('User authentication information is missing');
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onFinish = async (values: ILoginRequest) => {
    const updatedValues = { ...values, staySignedIn };
    const fetchedUserAuth = await userAuthStore.handleEmailLogin(updatedValues);
    if (fetchedUserAuth === undefined) {
      return;
    }
    setUserAuth(fetchedUserAuth);
    if (fetchedUserAuth.optOutMFA === true) {
      const fallbackAuthkey = generateAuthKey!(fetchedUserAuth.emailAddress, fetchedUserAuth.password);
      await authenticateUser!(undefined, fallbackAuthkey);
      if (fetchedUserAuth !== undefined) {
        setTimeout(() => {
          history.push('/account/dashboard');
        }, 600);
      }
      return;
    }
    try {
      const { emailAddress } = values;
      await emailTokenStore.sendVerificationCode({ emailAddress, preferredVerificationMethod: 'email', registration: false });
      showModal();
    } catch (error) {
      message.error('Could not send verification code');
    }

    try {
      mixpanel.track('Login Attempt', {
        emailAddress: values.emailAddress,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishFailed = () => {
    message.error('Could not login');
  };

  // eslint-disable-next-line no-unused-vars

  const handleResetLoginFormSubmit = (values: any) => {
    const { emailAddress } = values;
    authStore.requestPasswordReset({ emailAddress });
    setIsFormModalOpen(false);
  };

  useEffect(() => handleReturnUrl(), [isLoggedIn]);
  interface IModalContent {
  name: string;
  label: string;
  whitespace: boolean,
  pattern: RegExp | undefined;
  type: 'string' | 'email' | 'boolean';
  required: boolean;
  formType: 'input' | 'select' | 'radio' | 'checkbox' | 'textarea';
  input: ReactNode;

}

  const loginForm = [
    {
      name: 'emailAddress',
      label: 'Email address',
      required: true,
      formType: 'input',
      pattern: '',
      input: <Form.Input placeholder="Enter your email address" />,
    },
    {
      name: 'password',
      label: 'Password',
      required: true,
      formType: 'input',
      pattern: '',
      input: <Form.InputPassword placeholder="Enter your password" />,
    },
  ];
  const ModalContent: IModalContent[] = [
    {
      name: 'verificationEmail',
      whitespace: true,
      type: 'string',
      pattern: undefined,
      required: false,
      formType: 'input',
      label: 'Please enter the verification code sent to your email',
      input: <Form.Input placeholder="Enter Verification Code " onChange={(value) => setVerificationCode(value.target.value)} />,
    },
  ];

  useEffect(() => {
    if (loginUserAuth && loginUserAuth.clients && loginUserAuth.clients.length > 0) {
      const { billingPhone } = loginUserAuth.clients[0];
      setMaskedPhoneNumber(`*******${billingPhone.slice(-3)}`);
    }
  }, [loginUserAuth]);

  const SMSModalContent: IModalContent[] = [
    {
      name: 'verificationSMS',
      whitespace: true,
      type: 'string',
      pattern: undefined,
      required: false,
      formType: 'input',
      label: 'Please enter the verification code sent to your phone',
      input: <Form.Input placeholder="Enter Verification Code" onChange={(value) => setVerificationCode(value.target.value)} />,
    },
  ];

  const currentModalContent = isSMS ? SMSModalContent : ModalContent;
  const handleSMSVerification = async (emailAddress: string) => {
    try {
    // Use the same function for sending the verification code
      await emailTokenStore.sendVerificationCode({ emailAddress, preferredVerificationMethod: 'sms' });
      showModal();
    } catch (error) {
      message.error('Could not send verification code');
    }
  };
  const handleEmailVerification = async (emailAddress: string) => {
    try {
    // Use the same function for sending the verification code
      await emailTokenStore.sendVerificationCode({ emailAddress, preferredVerificationMethod: 'email' });
      showModal();
    } catch (error) {
      message.error('Could not send verification code');
    }
  };

  return (
    <main id="login">
      <Helmet>
        <title>Login - G. Fox</title>
      </Helmet>
      <div className={styles.loginCon}>
        <div className={styles.loginFormCon}>
          <Title
            className={styles.loginFormTitle}
            level={2}
          >
            Login
          </Title>
          {/* <div className={styles.loginFormSocialButtons}>
            <div id="google-one-tap-button" className={styles.googleSignIn} />
            <Text className={styles.loginOR}>
              OR
            </Text>
            <AppleButton />
          </div> */}

          <AntdForm
            name="login"
            className="custom-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
            validateMessages={validateMessages}
          >
            {React.Children.toArray(
              loginForm.map((item) => (
                <div className={styles.loginFormItemCon}>
                  <Text className={styles.loginFormItemLabel}>
                    {item.label}
                  </Text>
                  <AntdForm.Item
                    name={item.name}
                    required={item.required}
                    rules={[{ required: item.required }]}
                  >
                    <div className={styles.loginFormItem}>
                      {item.input}
                    </div>
                  </AntdForm.Item>
                </div>
              )),
            )}
            <div>
              <Checkbox onChange={onChange}>Stay signed in for a week</Checkbox>
            </div>
            <div className={styles.loginFormOptsCon}>
              <Buttons.PrimaryBtn
                className={styles.loginFormResetBtn}
                type="text"
                onClick={() => setIsFormModalOpen(true)}
              >
                Forgot password
              </Buttons.PrimaryBtn>
            </div>

            <div className={styles.loginFormOptsCon}>
              <Buttons.PrimaryBtn
                className={styles.loginFormSubmitBtn}
                htmlType="submit"
              >
                Login
              </Buttons.PrimaryBtn>
              <Buttons.PrimaryBtn
                type="ghost"
                className={styles.loginFormSignUpLargeBtn}
                onClick={handleEnter}
              >
                Sign Up
              </Buttons.PrimaryBtn>

            </div>

            <div className={styles.loginFormSignUpTextCon}>
              <Text className={styles.loginFormSignUpText}>
                Don’t have an account?
                {' '}
              </Text>
              <Buttons.PrimaryBtn
                className={styles.loginFormSignUpBtn}
                type="text"
                onClick={handleEnter}
              >
                Sign Up
              </Buttons.PrimaryBtn>
            </div>
          </AntdForm>
        </div>
        <div
          className={styles.loginFormBanner}
          style={{ backgroundImage: `url(${Images.SafetyHelmet})` }}
        >
          <Text className={styles.loginFormBannerText}>
            SAFETY
            <br />
            EQUIPMENT
          </Text>
        </div>
      </div>

      <Modal
        title="Verification"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className={styles.inputcon}>
          {React.Children.toArray(
            currentModalContent.map((item) => (
              <div
                className={[
                  styles.registerFormCon,
                  item.formType === 'input' && styles.registerFormConInput,
                  item.formType === 'textarea' && styles.registerFormConTextArea,
                ].join(' ')}
              >
                <Text className={styles.registerLabel}>{item.label}</Text>
                <Text>

                  {maskedPhoneNumber}

                </Text>
                <AntdForm.Item
                  name={item.name}
                  required={item.required}
                  rules={[{ required: item.required }, { whitespace: item.whitespace }]}
                >
                  <div className={styles.regsiterFormItem}>{item.input}</div>
                  <div className={styles.buttonContainer}>
                    <Button onClick={() => {
                      setIsSMS(!isSMS);
                      if (!isSMS && loginUserAuth) {
                        handleSMSVerification(loginUserAuth?.emailAddress);
                      }
                      if (isSMS && loginUserAuth) {
                        handleEmailVerification(loginUserAuth?.emailAddress);
                      }
                    }}
                    >
                      {isSMS ? 'Switch to Email' : 'Switch to SMS Verification'}
                    </Button>
                  </div>

                </AntdForm.Item>
              </div>
            )),
          )}
        </div>
      </Modal>

      <Modal
        title="Reset Password"
        className={styles.checkoutModal}
        visible={isFormModalOpen}
        onOk={() => resetLoginForm.submit()}
        onCancel={() => setIsFormModalOpen(false)}
        okText="Continue"
        cancelText="Cancel"
        okButtonProps={{ style: { height: '3em' } }}
        cancelButtonProps={{ style: { height: '3em' } }}
        closeIcon={(<IoClose />)}
      >
        <div className={styles.formModalBody}>
          <AntdForm
            form={resetLoginForm}
            name="login-reset"
            validateMessages={validateMessages}
            className="custom-form"
            onFinish={handleResetLoginFormSubmit}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
            style={{ width: '100%' }}
          >
            <AntdForm.Item
              name="emailAddress"
              rules={[{ required: true }, { type: 'email' }]}
            >
              <Form.Input placeholder="Email address" />
            </AntdForm.Item>
          </AntdForm>
        </div>
      </Modal>
    </main>
  );
});

export default Login;
