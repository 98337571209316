import { setBaseUrl } from '@bridgelabsdesign/gfox-api-client';
import React from 'react';
import './App.css';
import mixpanel from 'mixpanel-browser';
import { AuthProvider } from './context/AuthContext';
import { init as sentryInit } from './utils/sentry';
import Router from './route';

setBaseUrl(process.env.REACT_APP_GFOX_API_BASE_URL);

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ?? '', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

sentryInit();

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router />
      </AuthProvider>
    </div>
  );
}

export default App;
